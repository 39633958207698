<template>
  <div class="application-exchange-code">
    <en-table-layout
      :tableData="tableData.data"
      @selection-change="handleSelectionChange2"
    >
      <template slot="toolbar">
        <div class="col toolbar-title">兑换码列表</div>
      </template>

      <template slot="table-columns">
        <el-table-column label="编号">
          <template slot-scope="scope">
            {{ scope.$index + 1 + (tableData.page_no - 1) * tableData.page_size }}
          </template>
        </el-table-column>
        <el-table-column label="生成时间" width="160">
          <template slot-scope="scope">
            {{ scope.row.create_time | unixToDate('yyyy-MM-dd hh:mm:ss') }}
          </template>
        </el-table-column>
        <el-table-column label="面值">
          <template slot-scope="scope">
            {{ scope.row.card_value ? scope.row.card_value : 0 | unitPrice }}
          </template>
        </el-table-column>
        <el-table-column label="总库存">
          <template slot-scope="scope">{{ scope.row.key_num }}</template>
        </el-table-column>
        <el-table-column label="激活数">
          <template slot-scope="scope">{{ scope.row.active_num }}</template>
        </el-table-column>
        <el-table-column label="禁用数">
          <template slot-scope="scope">
            {{ scope.row.unactive_num }}
          </template>
        </el-table-column>
        <el-table-column label="已领取数">
          <template slot-scope="scope">
            {{ scope.row.exchange_num }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="80">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="handleEdit(scope.$index, scope.row)"
            >
              详情
            </el-button>
          </template>
        </el-table-column>
      </template>

      <el-pagination
        slot="pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage3"
        :page-size="params.page_size"
        :page-sizes="MixinPageSizes"
        :layout="MixinTableLayout"
        background
        :total="total"
      ></el-pagination>
    </en-table-layout>

    <dhmMsg ref="detail"></dhmMsg>
  </div>
</template>

<script>
import * as API_coupon from '@/api/coupon';
import dhmMsg from './dhmMsg';
import EnTableLayout from '../../../ui-components/TableLayout/src/main';

export default {
  name: 'exchange-code-list',
  data() {
    return {
      total: 0,
      flag: false,
      params: {
        page_no: 1,
        page_size: 20,
        mall_type: '',
        shopDisable: '',
        keyword: '',
        card_id: ''
      },

      currentPage3: 1,
      tableData: {},
      search: '',

      formInline: {
        user: ''
      }
    };
  },
  components: {
    EnTableLayout,
    dhmMsg
  },
  created() {
    if (this.$route.query.fquery) {
      this.flag = true;
      this.params.card_id = this.$route.query.card_id;
    }
    this.getyhj();
  },
  methods: {
    getyhj() {
      this.params.card_id = this.$route.query.card_id;
      API_coupon.cardList(this.params).then(res => {
        this.tableData = res;
        this.total = res.data_total;
      });
    },
    handleSelectionChange2(val) {
      this.tableData = val;
    },
    handleSizeChange(val) {
      this.params.page_size = val;
    },
    handleCurrentChange(val) {
      this.currentPage3 = val;
      this.params.page_no = val;
      this.getyhj();
    },
    handleEdit(index, row) {
      this.params.card_id = row.card_id;
      this.params.log_id = row.id;
      this.$refs.detail.show(this.params);
    }
  }
};
</script>

<style scoped lang="scss">
</style>
