<template>
  <x-dialog :proxy="detailDialog">
    <en-table-layout
      id="picture"
      :tableData="tableData.data"
      @selection-change="handleSelectionChange"
    >
      <template slot="toolbar">
        <el-form-item label="激活状态">
          <el-select size="medium" v-model="inform.active_status" clearable>
            <el-option
              v-for="item in options1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="编号">
          <el-input
            style="width: 200px"
            size="medium"
            v-model="inform.card_code"
            placeholder="请输入编号搜索"
            clearable
          ></el-input>
        </el-form-item>

        <el-button @click="seachMember" size="small" type="primary">
          搜索
        </el-button>

        <div class="col"></div>

        <el-button size="small" type="primary" @click="addmember(1)">
          激活选中
        </el-button>

        <el-button size="small" type="primary" @click="addmember(0)">
          禁用选中
        </el-button>

        <el-button size="small" type="primary" @click="addmember(2)">
          激活全部
        </el-button>

        <el-button size="small" type="primary" @click="addmember(3)">
          禁用全部
        </el-button>

        <el-button size="small" type="primary" @click="downloadPicture">
          导出二维码
        </el-button>

        <el-button size="small" type="primary" @click="memberfz(4)" :loading="exportAllLoading">
          导出全部
        </el-button>

        <el-button size="small" type="primary" @click="handleClickRecord">
          操作记录
        </el-button>
      </template>

      <template slot="table-columns">
        <el-table-column type="selection" width="55"></el-table-column>

        <el-table-column label="序号" width="100">
          <template slot-scope="scope">{{ scope.row.id }}</template>
        </el-table-column>

        <el-table-column prop="card_code" label="编号" width="150">
          <template slot-scope="scope">{{ scope.row.card_code }}</template>
        </el-table-column>

        <el-table-column prop="card_key" label="兑换码" width="125">
          <template slot-scope="scope">{{ scope.row.card_key }}</template>
        </el-table-column>

        <el-table-column prop="qr_code" label="兑换链接">
          <template slot-scope="scope">
            <el-popover placement="top" trigger="hover" popper-class="goods-name-popper">
              <span style="margin-right:5px;">{{ scope.row.qr_code_url }}</span>
              <el-button size="mini" @click="copyLinkName(scope.row.qr_code_url)">复制</el-button>
              <div class="goods-name text-ellipsis-2" slot="reference">
                {{ scope.row.qr_code_url }}
              </div>
            </el-popover>
          </template>
        </el-table-column>

        <el-table-column prop="qr_code" label="二维码" width="100">
          <template slot-scope="scope">
            <qrcode-vue
              :value="scope.row.qr_code_url"
              :size="80"
              level="H"
            ></qrcode-vue>
          </template>
        </el-table-column>

        <el-table-column
          prop="binding_member_name"
          label="绑定用户"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.binding_member_name }}
          </template>
        </el-table-column>

        <el-table-column prop="binding_time" label="绑定时间" width="160">
          <template slot-scope="scope">
            {{ scope.row.binding_time | unixToDate('yyyy-MM-dd hh:mm:ss') }}
          </template>
        </el-table-column>

        <el-table-column prop="status" label="状态" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.active_status === 0 ? '未激活' : '已激活' }}
          </template>
        </el-table-column>

        <el-table-column prop="active_name" label="操作人" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.active_name }}</template>
        </el-table-column>

        <el-table-column label="操作" show-overflow-tooltip width="80">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              v-if="scope.row.active_status === 0"
              @click="handleEdit(scope.$index, scope.row)"
            >
              激活
            </el-button>
            <el-button
              size="mini"
              type="danger"
              plain
              v-if="scope.row.active_status !== 0"
              @click="handleDelete(scope.$index, scope.row)"
            >
              禁用
            </el-button>
          </template>
        </el-table-column>
      </template>

      <el-pagination
        slot="pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="inform.page_no"
        :page-size="inform.page_size"
        :page-sizes="MixinPageSizes"
        :layout="MixinTableLayout"
        background
        :total="inform.total"
      ></el-pagination>
    </en-table-layout>

    <x-dialog :proxy="opsDialog">
      <en-table-layout
        toolbar
        pagination
        :tableData="recordTableData.data"
        :loading="loading"
      >
        <template slot="toolbar">
          <el-form-item label="操作类型">
            <el-select
              size="medium"
              v-model="recordinform.oper_type"
              style="width: 80px"
              clearable
            >
              <el-option label="全部" value=""></el-option>
              <el-option label="激活" value="2"></el-option>
              <el-option label="禁用" value="1"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="关键词">
            <el-input
              v-model="recordinform.key_word"
              size="medium"
              style="width: 220px"
              clearable
              placeholder="请输入卡号/卡密进行搜索"
            />
          </el-form-item>

          <el-button size="small" type="primary" @click="getrecordList">
            搜索
          </el-button>

          <div class="col"></div>

          <el-button size="small" type="primary" @click="ExportGetrecordList">
            导出全部
          </el-button>
        </template>

        <template slot="table-columns">
          <el-table-column label="编号">
            <template slot-scope="scope">{{ scope.$index + 1 }}</template>
          </el-table-column>

          <el-table-column prop="goods_name" label="操作时间" width="160">
            <template slot-scope="scope">
              {{ scope.row.create_time | unixToDate('yyyy-MM-dd hh:mm:ss') }}
            </template>
          </el-table-column>

          <el-table-column prop="oper_type" label="操作类型">
            <template slot-scope="scope">
              {{ scope.row.oper_type === 1 ? '禁用' : '激活' }}
            </template>
          </el-table-column>

          <el-table-column prop="card_code" label="卡号"/>
          <el-table-column prop="card_key" label="卡密"/>
          <el-table-column prop="opr_name" label="操作人"/>
          <el-table-column prop="ip" label="ip地址"/>
        </template>
        <el-pagination
          background
          slot="pagination"
          v-if="recordTableData"
          @size-change="handlePageSizeChange1"
          @current-change="handlePageCurrentChange1"
          :current-page="recordTableData.page_no"
          :page-sizes="MixinPageSizes"
          :page-size="recordTableData.page_size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="recordTableData.data_total"
        ></el-pagination>
      </en-table-layout>
    </x-dialog>
  </x-dialog>
</template>

<script>
import * as API_coupon from '@/api/coupon';
import QrcodeVue from 'qrcode.vue';
import FileSaver from 'file-saver';
import JSZip from 'jszip';
import {Foundation} from '@/../ui-utils';
import {mapGetters} from 'vuex';
import {$copy} from '@/pages/floor/renovation/services/copy';
import EnTableLayout from '../../../ui-components/TableLayout/src/main';
import XDialog from '@/components/x-dialog/x-dialog';
import {$xDialog} from '@/components/x-dialog/dialog.proxy';

export default {
  name: 'dhmMsg',
  computed: {
    ...mapGetters(['shopInfo'])
  },
  components: {
    XDialog,
    EnTableLayout,
    QrcodeVue
  },
  data() {
    return {
      yfather: null,
      detailDialog: $xDialog.create({
        title: '兑换码详情',
        disableConfirm: true,
        disableCancel: true,
        width: '95vw',
      }),
      opsDialog: $xDialog.create({
        title: '操作记录',
        disableConfirm: true,
        disableCancel: true,
        width: '80vw',
      }),
      loading: false,
      exportAllLoading: false,
      flag: false,
      qrcodePre: '',
      parentmsg: '',
      options: [
        {
          label: '未激活',
          value: 0
        },
        {
          label: '已激活',
          value: 1
        },
        {
          label: '已发放',
          value: 2
        },
        {
          label: '已兑换',
          value: 3
        },
        {
          label: '已使用',
          value: 4
        },
        {
          label: '卡密超时失效',
          value: 5
        }
      ],
      options1: [
        {
          label: '未激活',
          value: 0
        },
        {
          label: '已激活',
          value: 1
        }
      ],

      okno: {
        ids: [],
        log_id: 0
      },

      inform: {
        card_code: '',
        selectValue: '',
        page_size: 20,
        page_no: 1,
        total: 0,
        active_status: ''
      },
      page: {},
      tableData: {},
      multipleSelection: [],
      idsList: [],

      // 查看操作记录
      recordFlag: false,
      recordTableData: '',
      recordinform: {
        page_size: 20,
        page_no: 1,
        total: 0,
        oper_type: '',
        key_word: ''
      }
    };
  },
  methods: {
    show(params) {
      this.yfather = params;
      this.qrcodePre = 'https://' + this.shopInfo.domain_name + this.$url;
      this.getList().then(() => {
        this.detailDialog.display();
      });
    },
    // 导出操作记录
    ExportGetrecordList() {
      let obj = {};
      obj.card_id = this.yfather.card_id;
      obj.log_id = this.yfather.log_id;
      obj.page_no = 0;
      obj.page_size = 0;
      API_coupon.getCardsKeyOperationLog(obj)
        .then(res => {
          const json = {
            sheet_name: '操作日志',
            sheet_values: res.data.map((item, idx) => ({
              编号: idx + 1,
              操作时间: Foundation.unixToDate(item.create_time),
              操作类型: item.oper_type === 1 ? '禁用' : '激活',
              卡号: item.card_code,
              卡密: item.card_key,
              操作人: item.opr_name,
              ip地址: item.ip
            }))
          };
          this.MixinExportJsonToExcel(json, '操作日志');
        })
        .catch(_ => {
          this.loading = false;
        });
    },
    // 查看操作记录
    handleClickRecord() {
      this.getrecordList().then(() => {
        this.opsDialog.display();
      });
    },
    getrecordList() {
      this.loading = true;
      let obj = {};
      obj.card_id = this.yfather.card_id;
      obj.log_id = this.yfather.log_id;
      obj.page_no = this.recordinform.page_no;
      obj.page_size = this.recordinform.page_size;
      obj.oper_type = this.recordinform.oper_type;
      obj.key_word = this.recordinform.key_word;
      return API_coupon.getCardsKeyOperationLog(obj).then(res => {
        this.loading = false;
        this.recordTableData = res;
        this.recordinform.total = res.data_total;
        this.recordinform.page_no = res.page_no;
        this.recordinform.page_size = res.page_size;
      }).catch(_ => {
        this.loading = false;
      });
    },
    // 操作记录分页
    handlePageSizeChange1(val) {
      this.recordinform.page_size = val;
      this.getrecordList();
    },
    // 操作记录分页
    handlePageCurrentChange1(val) {
      this.recordinform.page_no = val;
      this.getrecordList();
    },
    // 导出二维码
    downloadPicture() {
      if (!this.idsList.length) {
        this.$message.error('请勾选数据');
        return;
      }
      const zip = new JSZip();
      const cache = {};
      let myCanvas = document
        .getElementById('picture')
        .getElementsByTagName('canvas');
      for (let index = 0; index < myCanvas.length; index++) {
        let data = myCanvas[index].toDataURL('image/png');

        if (this.idsList.includes(this.tableData.data[index].id)) {
          let fileName = this.tableData.data[index].id;

          zip.file(fileName + '.png', data.substring(22), {base64: true});
          cache[fileName] = data;
        }
      }
      zip.generateAsync({type: 'blob'}).then(content => {
        // 生成二进制流
        FileSaver.saveAs(content, '二维码.zip'); // 利用file-saver保存文件
      });
    },
    handleCommand(command) {
      this.yfather.status = Number(command);
    },
    allSelectionChange() {
      // 激活全部
      this.okno.ids = this.tableData.data.map(res => {
        return item.id;
      });
      this.okno.log_id = this.tableData.data[0].log_id;
      console.log('selectionselection  oprater_all');
      let obj = {
        ids: 0,
        // log_id: row.log_id,
        oprater_all: 1,
        // active_status: 1,
        log_type: 1
      };
      API_coupon.updateCardsKeyStatus(obj).then(res => {
        console.log(res, '激活成功');
        this.getList();
      });
    },
    seachMember() {
      this.getList();
    },
    getList() {
      let obj = {};
      obj.card_id = this.yfather.card_id;
      obj.active_status = this.inform.active_status;
      obj.log_id = this.yfather.log_id;
      obj.card_code = this.inform.card_code;
      obj.page_no = this.inform.page_no;
      obj.page_size = this.inform.page_size;

      return API_coupon.getDhJcard(obj).then(res => {
        this.tableData = res;
        this.tableData.data.forEach(item => {
          // item.qr_code_url = this.qrcodePre + item.qr_code;
          // item.qr_code_url =
          //   "https://" + item.domain_name + this.$url + item.qr_code;
          item.qr_code_url =
            'https://' + item.domain_name + this.$url + 'member/my-wallet';
        });
        this.inform.total = res.data_total;
        this.inform.page_no = res.page_no;
        this.inform.page_size = res.page_size;
      });
    },
    memberfz(v) {
      let obj = {};
      obj.card_id = this.yfather.card_id;
      obj.active_status = this.inform.active_status;
      obj.log_id = this.yfather.log_id;
      obj.card_code = this.inform.card_code;
      obj.page_no = 0;
      obj.page_size = 0;
      this.exportAllLoading = true;
      this.parentmsg = v;

      const loading = this.$loading({text: '正在导出'});

      API_coupon.getDhJcard(obj).then(res => {
        window.location.href = res.data[0];
        this.exportAllLoading = false;

        setTimeout(() => {
          loading.close();
        }, 1000);

        // this.tableData = res;
        // const json = {
        //   sheet_name: "卡密列表",
        //   sheet_values: res.data.map(item => ({
        //     序号: item.id,
        //     编号: item.card_code,
        //     兑换码: item.card_key,
        //     兑换链接: "https://" + item.domain_name + this.$url + 'member/my-wallet',
        //     绑定用户: item.binding_member_name,
        //     绑定时间: Foundation.unixToDate(item.binding_time),
        //     状态: item.active_status === 0 ? "未激活" : "已激活",
        //     激活人: item.active_name
        //   }))
        // };
        // this.MixinExportJsonToExcel(json, "卡密列表");
      });
    },
    addmember(v) {
      // 1 激活选中    0 禁用选中   2激活全部      3 禁用全部
      let obj = {};
      if (v === 1 || v === 0) {
        if (!this.idsList.length) {
          this.$message.error('请勾选数据');
          return;
        }
      }
      if (v === 1) {
        obj = {
          ids: this.idsList,
          card_id: 0,
          operate_all: 0,
          active_status: 1
        };
      } else if (v === 0) {
        obj = {
          ids: this.idsList,
          card_id: 0,
          operate_all: 0,
          active_status: 0
        };
      } else if (v === 2) {
        obj = {
          ids: [0],
          card_id: this.yfather.card_id,
          log_id: this.yfather.log_id,
          count: this.inform.total,
          operate_all: 1,
          active_status: 1
        };
      } else if (v === 3) {
        obj = {
          ids: [0],
          card_id: this.yfather.card_id,
          operate_all: 1,
          active_status: 0,
          log_id: this.yfather.log_id,
          count: this.inform.total
        };
      }
      if (v === 3 || v === 2) {
        this.$confirm(
          `确定 ${v === 2 ? '全部激活' : '全部禁用'}，是否继续?`,
          '提示',
          {type: 'warning'}
        )
          .then(() => {
            API_coupon.updateCardsKeyStatus(obj)
              .then(res => {
                if (v === 1) {
                  this.$message.success('批量激活成功');
                } else if (v === 0) {
                  this.$message.success('批量禁用成功');
                } else if (v === 2) {
                  this.$message.success('全部激活成功');
                } else if (v === 3) {
                  this.$message.success('全部禁用成功');
                }
                this.getList();
              })
              .catch(() => {
                this.$message.error('操作失败');
              });
          })
          .catch(() => {
          });
      } else {
        API_coupon.updateCardsKeyStatus(obj)
          .then(res => {
            if (v === 1) {
              this.$message.success('批量激活成功');
            } else if (v === 0) {
              this.$message.success('批量禁用成功');
            } else if (v === 2) {
              this.$message.success('全部激活成功');
            } else if (v === 3) {
              this.$message.success('全部禁用成功');
            }
            this.getList();
          })
          .catch(() => {
            // this.$message.error("操作失败");
          });
      }
    },
    handleSelectionChange(val) {
      // if (val.length > 0) {
      //   this.okno.ids = val.map((item, index) => {
      //     return item.id;
      //   });
      //   this.okno.log_id = val[0].log_id;
      //   console.log(val);

      //   this.multipleSelection = val;
      // }
      let ids = [];
      val.forEach(item => {
        ids.push(item.id);
      });
      this.idsList = ids;
    },
    handleEdit(index, row) {
      //激活卡密
      console.log(index, row);
      let obj = {
        ids: [row.id],
        card_id: 0,
        operate_all: 0,
        active_status: 1
      };
      API_coupon.updateCardsKeyStatus(obj).then(res => {
        this.$message.success('激活成功');
        this.getList();
      });
    },
    handleDelete(index, row) {
      let obj = {
        ids: [row.id],
        card_id: 0,
        operate_all: 0,
        active_status: 0
      };
      API_coupon.updateCardsKeyStatus(obj).then(res => {
        this.$message.success('禁用成功');
        this.getList();
      });
    },
    handleSizeChange(val) {
      this.inform.page_size = val;
      this.getList();
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.inform.page_no = val;
      this.getList();
      console.log(`当前页: ${val}`);
    },
    copyLinkName(name) {
      $copy.copy(name).then(() => {
        this.$message.success('已复制');
      }, err => {
        this.$message.error('复制失败');
        console.log('复制失败', err);
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.inner-toolbar {
  display: block;
  padding: 0px;
}

/deep/ div.toolbar {
  display: block;
  height: auto;
  overflow: hidden;
  padding: 20px 0;
}

/deep/ .popinfo {
  .el-dialog__body {
    border: 1px solid #e5e5e5;

    .el-table {
      border: 1px solid #e5e5e5;
    }
  }
}

.fenye .block .el-pagination {
  padding: 10px 0px;
  text-align: center;
}

.userAdimin {
  padding: 10px 20px;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: #fff;
  /* position: absolute;
  top: 10px;
  left: 20px;*/
}

.tableList .is-leaf {
  text-align: center;
}

.tableList .el-table__body .el-table__row td .cell {
  text-align: center;
}

.tableList .el-table_1_column_1 .cell .el-checkbox__inner {
  background-color: #e6ecf7;
}
</style>
